import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { graphql, useStaticQuery } from "gatsby";
import classNames from "classnames";

import downloadPdf from "../../../static/declaration-forms-CN22.pdf";
import IconDownload from "../../svg/icon-download.svg";
import { FormField, FormButton, FormTextarea, FormRadio } from "../form";
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider";
import "./register.scss";

const ValidationSchema = Yup.object().shape({
  fromDate: Yup.string().required("Pasirinkite datą."),
  senderName: Yup.string().required("Privalomas laukas"),
  senderPhone: Yup.string().required("Privalomas laukas"),
  senderAddress: Yup.string().required("Privalomas laukas"),
  senderCity: Yup.string().required("Privalomas laukas"),
  senderZip: Yup.string().required("Privalomas laukas"),
  packageWeight: Yup.string().required("Privalomas laukas"),
  packageSize: Yup.string().required("Privalomas laukas"),
  packageDescription: Yup.string().required("Privalomas laukas"),
  receiverName: Yup.string().required("Privalomas laukas"),
  receiverPhone: Yup.string().required("Privalomas laukas"),
  receiverAddress: Yup.string().required("Privalomas laukas"),
  receiverCity: Yup.string().required("Privalomas laukas"),
  receiverZip: Yup.string().required("Privalomas laukas"),
});

const STATUSES = {
  READY: "READY",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  HAS_ERRORS: "HAS_ERRORS",
};

export const RegistrationForm = () => {
  const state = useContext(GlobalStateContext);
  const [status, setStatus] = useState(STATUSES.READY);

  const dispatch = useContext(GlobalDispatchContext);
  const formId = "registration-form";
  const allDates = useStaticQuery(
    graphql`
      query AllDatesQuery {
        file(relativePath: { eq: "registration-data.md" }) {
          childMarkdownRemark {
            frontmatter {
              dates {
                date
                from
              }
            }
          }
        }
      }
    `
  ).file.childMarkdownRemark.frontmatter.dates;

  console.log("state", state.from || "XX");
  const datesByCity = allDates.filter((e) => e.from === (state.from || "LT"));

  const handleTabClick = (from) => {
    state.from = from;
    state.date = "";
    formik.setFieldValue("fromDate", "");
    dispatch({ type: "SET_DATES" });
  };

  const handleDateClick = (date) => {
    state.date = date;
    dispatch({ type: "SET_DATES" });
  };

  const formik = useFormik({
    initialValues: {
      fromDate: state.date || '',
      from: state.from || "LT",
      senderName: "",
      senderPhone: "",
      senderAddress: "",
      senderCity: "",
      senderZip: "",
      senderEmail: "",
      packageWeight: "",
      packageSize: "",
      packageDescription: "",
      receiverName: "",
      receiverPhone: "",
      receiverAddress: "",
      receiverCity: "",
      receiverZip: "",
      message: "",
      "sure-name": "",
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      setStatus(STATUSES.IN_PROGRESS);
      const formData = values;

      formData["form-name"] = formId;

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      }).then(() => {
        setTimeout(() => {
          setStatus(STATUSES.COMPLETED);
        }, 1000);
      });
    },
  });

  console.log("state", state);
  console.log("fromik values", formik.values);

  return (
    <div>
      {status === STATUSES.HAS_ERRORS && (
        <div className="form__errors">
          <strong>
            Nepavyko išsiųsti registracijos. <br />
            Bandykite dar kartą vėliau.
          </strong>
        </div>
      )}
      {status !== STATUSES.COMPLETED && (
        <form
          name={formId}
          method="POST"
          onSubmit={formik.handleSubmit}
          netlify="true"
          netlify-honeypot="sure-name"
          data-netlify="true"
          data-netlify-honeypot="sure-name"
        >
          <input type="hidden" name="form-name" value={formId} />
          <div className="sure-name">
            <label>
              Don&apos;t fill this out if you&apos;re human:
              <input
                name="sure-name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
          </div>
          <div
            className={classNames("tabs", {
              "tabs--disabled":
                status === STATUSES.IN_PROGRESS ||
                status === STATUSES.COMPLETED,
            })}
          >
            <input
              className="tabs__radio"
              type="radio"
              name="from"
              value="LT"
              id="from-lt"
              defaultChecked={(state.from || "LT") === "LT"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <label
              className="tabs__tab"
              htmlFor="from-lt"
              onClick={() => handleTabClick("LT")}
            >
              <span className="tabs__label">Iš Lietuvos</span>
            </label>
            <input
              className="tabs__radio"
              type="radio"
              name="from"
              value="UK"
              id="from-uk"
              defaultChecked={state.from === "UK"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <label
              className="tabs__tab"
              htmlFor="from-uk"
              onClick={() => handleTabClick("UK")}
            >
              <span className="tabs__label">Iš Anglijos</span>
            </label>
          </div>
          <div className="form__wrapper">
            <fieldset className="form__group">
              <legend className="form__group-label">Išvykimo data</legend>
              <div
                className={classNames("fancy-radio", {
                  "fancy-radio--error":
                    formik.touched.fromDate && formik.errors.fromDate,
                })}
              >
                {datesByCity.map((listItem, index) => (
                  <FormRadio
                    name="fromDate"
                    id={`from-date-${index}`}
                    key={`${listItem}-${index}`}
                    label={listItem.date}
                    checked={state.date === listItem.date}
                    fieldValue={listItem.date}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onClick={() => handleDateClick(listItem.date)}
                    disabled={
                      status === STATUSES.IN_PROGRESS ||
                      status === STATUSES.COMPLETED
                    }
                  />
                ))}
                {formik.touched.fromDate && formik.errors.fromDate && (
                  <div className="form__field-error">
                    {formik.errors.fromDate}
                  </div>
                )}
              </div>
            </fieldset>
            <fieldset className="form__group">
              <legend className="form__group-label">Siuntėjo duomenys</legend>
              <FormField
                name="senderName"
                label="Siuntėjo vardas ir pavardė"
                value={formik.values.senderName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.senderName && formik.errors.senderName
                    ? formik.errors.senderName
                    : null
                }
              />
              <FormField
                name="senderPhone"
                label="Telefono numeris"
                value={formik.values.senderPhone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.senderPhone && formik.errors.senderPhone
                    ? formik.errors.senderPhone
                    : null
                }
              />
              <FormField
                name="senderAddress"
                label="Siuntėjo adresas (gatvė, namo nr.)"
                value={formik.values.senderAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.senderAddress && formik.errors.senderAddress
                    ? formik.errors.senderAddress
                    : null
                }
              />
              <FormField
                name="senderCity"
                label="Siuntėjo miestas"
                value={formik.values.senderCity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.senderCity && formik.errors.senderCity
                    ? formik.errors.senderCity
                    : null
                }
              />
              <FormField
                name="senderZip"
                label="Siuntėjo pašto kodas"
                value={formik.values.senderZip}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.senderZip && formik.errors.senderZip
                    ? formik.errors.senderZip
                    : null
                }
              />
              <FormField
                name="senderEmail"
                label="El. paštas"
                value={formik.values.senderEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.senderEmail && formik.errors.senderEmail
                    ? formik.errors.senderEmail
                    : null
                }
              />
              <FormField
                name="packageWeight"
                label="Siuntos svoris"
                value={formik.values.packageWeight}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.packageWeight && formik.errors.packageWeight
                    ? formik.errors.packageWeight
                    : null
                }
              />
              <FormField
                name="packageSize"
                label="Siuntos išmatavimai"
                value={formik.values.packageSize}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.packageSize && formik.errors.packageSize
                    ? formik.errors.packageSize
                    : null
                }
              />
              <FormTextarea
                name="packageDescription"
                label="Trumpas siuntos aprašymas"
                value={formik.values.packageDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.packageDescription &&
                  formik.errors.packageDescription
                    ? formik.errors.packageDescription
                    : null
                }
              />
            </fieldset>
            <fieldset className="form__group">
              <legend className="form__group-label">Gavėjo duomenys</legend>
              <FormField
                name="receiverName"
                label="Gavėjo vardas ir pavardė"
                value={formik.values.receiverName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.receiverName && formik.errors.receiverName
                    ? formik.errors.receiverName
                    : null
                }
              />
              <FormField
                name="receiverPhone"
                label="Gavėjo telefono numeris"
                value={formik.values.receiverPhone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.receiverPhone && formik.errors.receiverPhone
                    ? formik.errors.receiverPhone
                    : null
                }
              />
              <FormField
                name="receiverAddress"
                label="Gavėjo adresas (gatvė, namo nr.)"
                value={formik.values.receiverAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.receiverAddress &&
                  formik.errors.receiverAddress
                    ? formik.errors.receiverAddress
                    : null
                }
              />
              <FormField
                name="receiverCity"
                label="Gavėjo miestas"
                value={formik.values.receiverCity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.receiverCity && formik.errors.receiverCity
                    ? formik.errors.receiverCity
                    : null
                }
              />
              <FormField
                name="receiverZip"
                label="Gavėjo pašto kodas"
                value={formik.values.receiverZip}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.receiverZip && formik.errors.receiverZip
                    ? formik.errors.receiverZip
                    : null
                }
              />
              <FormTextarea
                name="message"
                label="Komentarai"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
                error={
                  formik.touched.message && formik.errors.message
                    ? formik.errors.message
                    : null
                }
              />
            </fieldset>
            <div className="form__field-wrapper">
              <FormButton
                type="submit"
                disabled={
                  status === STATUSES.COMPLETED ||
                  status === STATUSES.IN_PROGRESS ||
                  status === STATUSES.COMPLETED
                }
              >
                {status === STATUSES.IN_PROGRESS
                  ? "Registruojam siuntą.."
                  : "Registruoti siuntą"}
              </FormButton>
            </div>
          </div>
        </form>
      )}
      {status === STATUSES.COMPLETED && (
        <div className="form__message-wrapper">
          <h2 className="form__message-title">
            Dėkojame už pateiktą užsakymą!
          </h2>
          <p className="form__message-text">
            Jūsų užklausa yra apdorojama. Prireikus papildomos informacijos,
            susisieksime su jumis.
          </p>

          <div className="form__customs-message">
            <h2 className="form__message-title">Siuntos deklaracija</h2>
            <p className="form__message-text">
              Nuo 2021 m. Sausio 1 d. siunčiant siuntas į JK ir iš UK į Lietuvą
              yra privaloma pildyti pašto muitinės deklaraciją kiekvienai
              siuntai. Atsispausdinkite CN22 deklaracijos formą ir užpildę
              pridėkite ją prie siuntos. Jei negalite to padaryti mūsų
              varuotojai duos užpildyti CN22 formą atvažiavę pasiimti siuntos.
            </p>
            <a className="customs-form__link" href={downloadPdf}>
              <IconDownload className="customs-form__icon" />
              <span>
                Parsisiųsti muitinės <br />
                deklaracijos formą
              </span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
