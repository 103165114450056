import React from "react";
import { graphql } from "gatsby";
import "./register/scss/register.scss";
import { RegisterForm } from "./register/register-form";
import Layout from "../components/mainLayout/MainLayout.jsx";
import { RegistrationForm } from "../components/registrationForm/RegistrationForm.jsx";

const RegisterPage = ({ data }) => {
  const { markdownRemark } = data;
  const pageTitle = markdownRemark.frontmatter.title;
  const pageDescription = markdownRemark.frontmatter.description;
  const pageForm = markdownRemark.frontmatter.form;

  return (
    <Layout
      withBackground
      title="Užsakymo forma. Siuntos į Angliją."
      description="Daugelį metų dirbame su siuntų pervežimo paslaugomis Lietuvoje bei kitose Europos Sąjungos šalyse. Ilgametė patirtis ir profesionalus požiūris mums leidžia užtikrinti išskirtinį krovinių saugumą, pristatymo greitį už optimalią kainą."
    >
      <div className="register">
        <div className="register__frame">
          <div className="register__headline">
            <h1 className="register__title">{pageTitle}</h1>
            <p className="register__desc">{pageDescription}</p>
          </div>
          <div className="register__row">
            <div className="register__form">
              <div className="form">
                <RegistrationForm />
              </div>
            </div>
            <div className="register__rules">
              <h3 className="register__rules-title">Taisyklės siuntoms</h3>
              <p className="register__rules-desc">
                Prieš paimant siuntą, ji turi būt supakuota, švari ir nekelti
                pavojaus kitoms siuntoms. Ant siuntos turi būti užrašytas gavėjo
                adresas ir kontaktinis telefono numeris. Klientas atsako už
                siuntos įpakavimą ir joje esantį turinį bei nurodytus gavėjo
                duomenis. Prieš atvažiuodami paimti / atiduoti siuntą klientui,
                apie tai pranešame nurodytu telefono numeriu. Nepavykus
                susisiekti su siuntėju/gavėju, siunta paliekama tarpiniame taške
                arba paėmimas / atidavimas yra atidedamas. Praradus siuntą dėl
                nenumatytų aplinkybių, įsipareigojame klientams kompensuoti iki
                200 € jos vertės. Gabenant didesnės vertės siuntas, prašome apie
                tai informuoti vairuotoją.
              </p>

              <h3 className="register__rules-title">Įspėjimas</h3>
              <p className="register__rules-desc">
                Kilus įtarimui dėl siuntos turinio, vairuotojas turi teisę
                siuntą patikrinti. Siuntose draudžiama vežti: cigaretes ar
                tabako gaminius, narkotines medžiagas, alkoholinius gėrimus,
                sprogstamas medžiagas, pinigus, asmens dokumentus, receptinius
                vaistus. Vairuotojas už pažeidimus neatsako –{" "}
                <strong>baudas apmoka klientas</strong>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RegisterPage;

export const pageQuery = graphql`
  query RegisterPageTemplate($id: String!) {
    markdownRemark(
      id: { eq: $id }
      frontmatter: { templateKey: { eq: "register" } }
    ) {
      frontmatter {
        title
        description
        form
      }
    }
  }
`;
