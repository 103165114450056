import { Formik, Form } from "formik";
import React, { useContext, useState } from "react";
import * as Yup from "yup";
import {
  FormField,
  FormButton,
  FormTextarea,
  FormRadio,
} from "../../components/form";
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider";
import { graphql, useStaticQuery } from "gatsby";
import downloadPdf from "../../../static/declaration-forms-CN22.pdf";
import IconDownload from "../../svg/icon-download.svg";

const validationSchema = Yup.object().shape({
  senderName: Yup.string().required("Privalomas laukas"),
  senderPhone: Yup.string().required("Privalomas laukas"),
  senderAddress: Yup.string().required("Privalomas laukas"),
  senderCity: Yup.string().required("Privalomas laukas"),
  senderZip: Yup.string().required("Privalomas laukas"),
  packageWeight: Yup.string().required("Privalomas laukas"),
  packageSize: Yup.string().required("Privalomas laukas"),
  packageDescription: Yup.string().required("Privalomas laukas"),
  receiverName: Yup.string().required("Privalomas laukas"),
  receiverPhone: Yup.string().required("Privalomas laukas"),
  receiverAddress: Yup.string().required("Privalomas laukas"),
  receiverCity: Yup.string().required("Privalomas laukas"),
  receiverZip: Yup.string().required("Privalomas laukas"),
});

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export function RegisterForm() {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [formSubmitted, setFormSubmission] = useState(false);
  const pageQuery = useStaticQuery(
    graphql`
      query RegistrationQueryForm {
        allFile(filter: { relativePath: { eq: "registration-data.md" } }) {
          edges {
            node {
              childMarkdownRemark {
                frontmatter {
                  dates {
                    from
                    date
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const registrationDates =
    pageQuery.allFile.edges[0].node.childMarkdownRemark.frontmatter;
  const allDates =
    pageQuery.allFile.edges[0].node.childMarkdownRemark.frontmatter.dates;

  const setDefaultChecked = (item, index) => {
    const date =
      state.fromLocation === "Lietuva" ? state.fromDateLT : state.fromDateUK;

    if (date === "") {
      return index === 0 ? "checked" : "";
    } else {
      return item === date ? "checked" : "";
    }
  };

  const DatesList = () => {
    const _from = state.fromLocation === "Lietuva" ? "LT" : "UK";
    const list = allDates.filter((e) => e.from === _from);

    console.log("list", list);
    console.log("state", state);

    return (
      <div className="fancy-radio">
        {list.map((listItem, index) => (
          <FormRadio
            key={index}
            index={index}
            name="fromDate"
            label={listItem.date}
            active={setDefaultChecked(listItem.date, index)}
            fieldValue={listItem.date}
          />
        ))}
      </div>
    );
  };

  const handleTabClick = (location) => {
    state.fromLocation = location;
    dispatch({ type: "SET_DATES" });
  };

  const getDate = () => {
    const dateFromState =
      state.fromLocation === "Lietuva" ? state.fromDateLT : state.fromDateUK;
    const dateFromList =
      state.fromLocation === "Lietuva"
        ? registrationDates.dates_LT[0]
        : registrationDates.dates_UK[0];

    return dateFromState === "" ? dateFromList.date : dateFromState;
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(false);

    const formName = { "form-name": "registracija-siunta" };
    const formFrom = { from: state.fromLocation };
    const formDate = { fromDate: getDate() };
    const formData = { ...formName, ...values, ...formFrom, ...formDate };

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode(formData),
    })
      .then(() => {
        setFormSubmission(true);
      })
      .catch((error) => alert(error));
  };

  const setActiveTab = (from) => {
    return state.fromLocation === from ? "checked" : "";
  };

  return (
    <div>
      {!formSubmitted && (
        <Formik
          initialValues={{
            from: "",
            fromDate: "",
            senderName: "",
            senderPhone: "",
            senderAddress: "",
            senderCity: "",
            senderZip: "",
            senderEmail: "",
            packageWeight: "",
            packageSize: "",
            packageDescription: "",
            receiverName: "",
            receiverPhone: "",
            receiverAddress: "",
            receiverCity: "",
            receiverZip: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form data-netlify="true" name="registracija-siunta">
              <input type="hidden" name="from" />
              <input type="hidden" name="fromDate" />

              <div className="tabs">
                <input
                  className="tabs__radio"
                  type="radio"
                  name="from"
                  value="Lietuva"
                  id="from-lt"
                  defaultChecked={setActiveTab("Lietuva")}
                />
                <label
                  className="tabs__tab"
                  htmlFor="from-lt"
                  onClick={() => handleTabClick("Lietuva")}
                >
                  <span className="tabs__label">Iš Lietuvos</span>
                </label>
                <input
                  className="tabs__radio"
                  type="radio"
                  name="from"
                  value="Anglija"
                  id="from-uk"
                  defaultChecked={setActiveTab("Anglija")}
                />
                <label
                  className="tabs__tab"
                  htmlFor="from-uk"
                  onClick={() => handleTabClick("Anglija")}
                >
                  <span className="tabs__label">Iš Anglijos</span>
                </label>
              </div>
              <div className="form__wrapper">
                <fieldset className="form__group">
                  <legend className="form__group-label">Išvykimo data</legend>
                  <DatesList />
                </fieldset>
                <fieldset className="form__group">
                  <legend className="form__group-label">
                    Siuntėjo duomenys
                  </legend>
                  <FormField
                    name="senderName"
                    label="Siuntėjo vardas ir pavardė"
                    required
                  />
                  <FormField
                    name="senderPhone"
                    label="Telefono numeris"
                    required
                  />
                  <FormField
                    name="senderAddress"
                    label="Siuntėjo adresas (gatvė, namo nr.)"
                    required
                  />
                  <FormField
                    name="senderCity"
                    label="Siuntėjo miestas"
                    required
                  />
                  <FormField
                    name="senderZip"
                    label="Siuntėjo pašto kodas"
                    required
                  />
                  <FormField name="senderEmail" label="El. paštas" />
                  <FormField
                    name="packageWeight"
                    label="Siuntos svoris"
                    required
                  />
                  <FormField
                    name="packageSize"
                    label="Siuntos išmatavimai"
                    required
                  />
                  <FormTextarea
                    name="packageDescription"
                    label="Trumpas siuntos aprašymas"
                    required
                  />
                </fieldset>
                <fieldset className="form__group">
                  <legend className="form__group-label">Gavėjo duomenys</legend>
                  <FormField
                    name="receiverName"
                    label="Gavėjo vardas ir pavardė"
                    required
                  />
                  <FormField
                    name="receiverPhone"
                    label="Gavėjo telefono numeris"
                    required
                  />
                  <FormField
                    name="receiverAddress"
                    label="Gavėjo adresas (gatvė, namo nr.)"
                    required
                  />
                  <FormField
                    name="receiverCity"
                    label="Gavėjo miestas"
                    required
                  />
                  <FormField
                    name="receiverZip"
                    label="Gavėjo pašto kodas"
                    required
                  />
                  <FormTextarea name="message" label="Komentarai" />
                </fieldset>

                <div className="form__field-wrapper">
                  <FormButton type="submit">Registruoti siuntą</FormButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}

      {formSubmitted && (
        <div className="form__message-wrapper">
          <h2 className="form__message-title">
            Dėkojame už pateiktą užsakymą!
          </h2>
          <p className="form__message-text">
            Jūsų užklausa yra apdorojama. Prireikus papildomos informacijos,
            susisieksime su jumis.
          </p>

          <div className="form__customs-message">
            <h2 className="form__message-title">Siuntos deklaracija</h2>
            <p className="form__message-text">
              Nuo 2021 m. Sausio 1 d. siunčiant siuntas į JK ir iš UK į Lietuvą
              yra privaloma pildyti pašto muitinės deklaraciją kiekvienai
              siuntai. Atsispausdinkite CN22 deklaracijos formą ir užpildę
              pridėkite ją prie siuntos. Jei negalite to padaryti mūsų
              varuotojai duos užpildyti CN22 formą atvažiavę pasiimti siuntos.
            </p>
            <a className="customs-form__link" href={downloadPdf}>
              <IconDownload className="customs-form__icon" />
              <span>
                Parsisiųsti muitinės <br />
                deklaracijos formą
              </span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
